import React, { useEffect, useRef, useState } from "react"
// import { Placeholder } from 'semantic-ui-react';
const ProfilePic = ({imageSrc}) => {
    const [loaded, setLoaded] = useState(false)

    const image = useRef()

    const loadingStyles = {
        marginLeft: 'auto',
        marginRight: 'auto'
    }

    const imgStyles = {
        width: "100%",
        height: "100%",
        objectFit: "cover",
        marginLeft: 'auto',
        marginRight: 'auto',
        opacity: loaded ? 1 : 0
    }

    const handleLoad = () => setLoaded(true)

    useEffect(() => {
        if (image.current.complete) setLoaded(true)
    }, [])

    return (
        <div className="ProfilePic ui placeholder h-40 w-40 align-top object-center rounded-full mt-2" style={loadingStyles}>
            <img 
                className="h-40 w-40 align-top object-center rounded-full mt-0"
                ref={image}
                onLoad={handleLoad}
                style={imgStyles}
                src={imageSrc}
                alt="Profiles"
            />
        </div>
    )
}

export default ProfilePic