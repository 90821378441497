import React, { Component } from 'react'
import { Button, Card, /*Image,*/ Placeholder } from 'semantic-ui-react';
import { /*Header,*/ Icon, Label } from 'semantic-ui-react';
import { Segment } from 'semantic-ui-react'
import ImageGallery from 'react-image-gallery';
import { CopyToClipboard } from 'react-copy-to-clipboard';
//import ReactPlaceholder from 'react-placeholder';
import { StateProvider, ObjectWatcher } from 'reenhance-components';
import ProfilePic from './ProfilePic';
import { toast } from 'react-toastify';



// const cards = [
//   {
//     avatar: 'https://images.unsplash.com/photo-1573579016624-dc0137d1c2a9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=900&q=60',
//     date: 'Joined in 2013',
//     header: 'Helen',
//     description: 'Primary Contact',
//   },
//   {
//     avatar: 'https://images.unsplash.com/photo-1573579016624-dc0137d1c2a9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=900&q=60',
//     date: 'Joined in 2013',
//     header: 'Matthew',
//     description: 'Primary Contact',
//   },
//   {
//     avatar: 'https://images.unsplash.com/photo-1573579016624-dc0137d1c2a9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=900&q=60',
//     date: 'Joined in 2013',
//     header: 'Molly',
//     description: 'Primary Contact',
//   },
// ]

const modelCode = 'DEX15';
const shopName = 'Dexter\'s Tag Lab';

const images1 = [
    {
      original: 'https://res.cloudinary.com/modeldog/image/upload/v1599709806/photo_2020-09-09_22.49.50_uxj9rn.jpg'
    },
    {
      original: 'https://res.cloudinary.com/modeldog/image/upload/v1599709931/118951878_649286939344631_6966754838914123619_n_hlo2x9.jpg'
    },
    {
        original: 'https://res.cloudinary.com/modeldog/image/upload/v1599709990/117315470_300385451293714_2001243733458666516_n_iqfhoj.jpg'
    }
  ];

  const images = [
    {
      original: 'https://res.cloudinary.com/modeldog/image/upload/v1599507614/IMAGE_2020-09-07_14_40_11_m5tsxx.jpg'
    },
    {
      original: 'https://res.cloudinary.com/modeldog/image/upload/v1599505723/2374921829038323542_ulwsjn.jpg'
    },
    {
        original: 'https://res.cloudinary.com/modeldog/image/upload/v1599508095/2374921828987930277_w4zlvp.jpg'
    }
   
  ];

  const images2 = [
    {
      original: 'https://res.cloudinary.com/modeldog/image/upload/v1599711009/photo_2020-09-09_23.10.00_eo3udm.jpg'
    },
    {
      original: 'https://scontent-lax3-1.cdninstagram.com/v/t51.2885-15/sh0.08/e35/c0.180.1440.1440a/s640x640/118667358_701019263826360_169428041326664891_n.jpg?_nc_ht=scontent-lax3-1.cdninstagram.com&_nc_cat=100&_nc_ohc=Vib2mwVBDcwAX_OKA9l&oh=38fc5eaf09a767de53ad61509f398497&oe=5F81D40C'
    },
    {
        original: 'https://scontent-lax3-1.cdninstagram.com/v/t51.2885-15/sh0.08/e35/c0.180.1440.1440a/s640x640/118574111_624409685117105_1850964379201245318_n.jpg?_nc_ht=scontent-lax3-1.cdninstagram.com&_nc_cat=105&_nc_ohc=s7Xszo9nzzsAX91ifSj&oh=4d953b106f79c784be455ce5eab65f0c&oe=5F81964D'
    }
  ];
  
  
const description = [
    'Personalized, Hand-stamped Pet ID tags, Keychains & Jewelry ',
  ].join(' ')

const LoadedState = StateProvider(false);
const GalleryRefWatcher = ObjectWatcher(React.createRef());

// const ImagesWithLoading = ({ images }) => (
//    <LoadedState>
//      {({ state: loaded, setState: setLoaded }) => (
//        <div style={{width: '290px', height:'290px'}}>
//          {!loaded ? (
//             <Placeholder style={{position:'relative'}}>
//                 <Placeholder.Image square />
//             </Placeholder>
//          ) : 
//          null}
//                        <ImageGallery 
//                         style={!loaded ? { transparency: '100%', visibility: 'hidden'} : {width: '100%', height:'100%'}}
//                         showBullets={loaded}
//                         showThumbnails={false}
//                         showIndex={false}
//                         showFullscreenButton={false}
//                         showNav={false}
//                         showPlayButton={false}
//                         stopPropagation={true}
//                         items={images}
//                         onImageLoad={() => //setTimeout(() => {
//                             setLoaded(true)
//                         //   }, 0)
//                         }>
//                     </ImageGallery>  
//        </div>
//      )}
//    </LoadedState>
//  );

const ImagesWithLoading = ({ images }) => (
    
    <LoadedState>
      {({ state: loaded, setState: setLoaded }) => (
        <GalleryRefWatcher watch="current">
          {(galleryRef) => {
            const complete = galleryRef.current && galleryRef.current.complete;
            return (
                <div style={{width: '290px', height:'290px'}}>
                    {!loaded ? (
                        <Placeholder style={{position:'relative'}}>
                            <Placeholder.Image square />
                        </Placeholder>
                    ) : 
                    null}
                    <div className="" style={!loaded? {transparency: '100%', visibility: 'hidden'} : {width: '100%', height:'100%'}}>
                    <ImageGallery 
                        showBullets={loaded}
                        showThumbnails={false}
                        showIndex={false}
                        showFullscreenButton={false}
                        showNav={false}
                        showPlayButton={false}
                        stopPropagation={true}
                        items={images}
                        ref={galleryRef}
                        onImageLoad={() => setLoaded(true)}
                        />
                    </div>
              </div>
            );
          }}
        </GalleryRefWatcher>
      )}
    </LoadedState>
);

const ToastAndGo = ({text, link}) => (
    // <div>{text}
    //     <Label className='align-middle right-0' link={link} color='gray' >
    //                             Go to the Shop
    //     </Label>
    // </div>

    <div class="flex justify-between">
        <div class="text-gray-700 text-center mt-3 px-4 py-2 m-2">{text}</div>
        {/* <div className="align-middle text-center">{text}</div> */}
        <div class="text-gray-700 text-center  px-4 py-2 m-2">
        <Label className='align-middle right-0' as='a' size='large' link={link} onClick={(link) => (window.location = "https://www.etsy.com/shop/dexterstaglab/")} >
                                 Go to the Shop
        </Label>
        </div>
    </div>
)


function deployCopyToast(toastText) {
    toast(<ToastAndGo text={toastText} link="https://www.etsy.com/shop/dexterstaglab/"></ToastAndGo>)
}
    


export default class PlaceholderExampleCard extends Component {
  state = { loading: true }


  handleLoadingClick = () => {
    this.setState({ loading: true })

    setTimeout(() => {
      this.setState({ loading: false })
    }, 3000)
  }

  

  render() {
    return (
      <>
      
        <Segment vertical>
        <div className="flex flex-col">
                <ProfilePic imageSrc={"https://res.cloudinary.com/modeldog/image/upload/v1599504782/IMAGE_2020-09-07_13_53_01_y5gwbu.jpg"} ></ProfilePic>
                {/* <div class="rounded-full items-center flex-shrink text-3xl object-center shadow-2xl bg-opacity-50 bg-gray-100 mt-2 py-2 px-4">@dexter_bcollie</div> */}
                <div className="mt-2">
                    <h2 className="ui center aligned icon header">
                    @dexter_bcollie
                    </h2>
                </div>

        </div>
                
                
        </Segment>
        


        {/* <Segment vertical> */}
        {/*    <Card.Group style={{marginTop: '25px'}} doubling itemsPerRow={3} stackable>
            {_.map(cards, (card) => (
                <Card key={card.header}>
                {loading ? (
                    <Placeholder>
                    <Placeholder.Image square />
                    </Placeholder>
                ) : (
                    <Card.Content>
                        <Card.Header>{card.header}</Card.Header>
                        <Image src={card.avatar} />
                    </Card.Content>
                    
                )}

                <Card.Content>
                    {loading ? (
                    <Placeholder>
                        <Placeholder.Header>
                        <Placeholder.Line length='very short' />
                        <Placeholder.Line length='medium' />
                        </Placeholder.Header>
                        <Placeholder.Paragraph>
                        <Placeholder.Line length='short' />
                        </Placeholder.Paragraph>
                    </Placeholder>
                    ) : (
                    <>
                        <Card.Header>{card.header}</Card.Header>
                        <Card.Meta>{card.date}</Card.Meta>
                        <Card.Description>{card.description}</Card.Description>
                    </>
                    )}
                </Card.Content>

                <Card.Content extra>
                    <Button disabled={loading} primary>
                    Add
                    </Button>
                    <Button disabled={loading}>Delete</Button>
                </Card.Content>
                </Card>
            ))}
            </Card.Group> */}

    <Card.Group style={{width: '100%', marginLeft: '0px', marginRight: '0px'}}>
            <Card style={{marginLeft: 'auto', marginRight: 'auto', marginTop: '25px'}}>
                <Card.Content style={{height: '52px'}}>
                <div className="mx-auto flex align-middle bg-white ">
                    <div className="flex-shrink-0">
                        <img className="h-12 w-12 align-top rounded-full -mt-2"  src="https://i.etsystatic.com/iusa/bbff98/75532108/iusa_75x75.75532108_gont.jpg?version=0" alt="Store Logo"/>
                    </div>
                    <div className="ml-4 pt-1">
                        <h4 className="md:text-left text-base text-gray-900 leading-tight">{shopName}</h4>
                    </div>
                    <div className="absolute pr-4 pt-1 align-middle right-0" onClick={() => {(window.location = "https://www.etsy.com/shop/dexterstaglab/")}}> 
                        <Icon link name='shop' />
                    </div>
                </div>
                </Card.Content>
                
                {/* {loading ? (
                    <Placeholder>
                        <Placeholder.Image square />
                    </Placeholder>
                ) : (
                    <ImageGallery 
                    showBullets={true}
                    showThumbnails={false}
                    showIndex={false}
                    showFullscreenButton={false}
                    showNav={false}
                    showPlayButton={false}
                    stopPropagation={true}
                    items={images}
                    onImageLoad={() => this.loading = false} >
                    </ImageGallery>      
                )} */}
                {/* <ReactPlaceholder ready={!loading} customPlaceholder={loadingPlaceholder}> */}
                    {/* <ImageGallery 
                        showBullets={true}
                        showThumbnails={false}
                        showIndex={false}
                        showFullscreenButton={false}
                        showNav={false}
                        showPlayButton={false}
                        stopPropagation={true}
                        items={images}
                        onImageLoad={() => this.loading = false} >
                    </ImageGallery>   */}
                    <ImagesWithLoading images={images} />
                {/* </ReactPlaceholder> */}
                
                <Card.Content>
                    <div className="relative">
                        <CopyToClipboard text={modelCode} onCopy={(text) => {deployCopyToast(`Copied: ${text}`)}}> 
                            <Label as='a' color='teal' >
                                Save 15% with {modelCode}
                            </Label>
                        </CopyToClipboard>  

                        <div className="absolute bottom-0 right-0 ">
                            <Icon link name='instagram' size='large' onClick={() => {(window.location = "https://www.instagram.com/dexters.taglab/")}} />
                        </div>   
                    </div> 
                    <div className="mt-2">
                        <p>{description}</p>
                    </div>              
                </Card.Content>
                <CopyToClipboard text={modelCode} onCopy={() => {(window.location = "https://www.etsy.com/shop/dexterstaglab/")}}>
                    <Button  attached='bottom' content='Copy Code & Visit Shop' icon='arrow right' labelPosition='right' />
                </CopyToClipboard>     
            </Card>

                    {/* PAWSITIVITY STARTS */}
            <Card style={{marginLeft: 'auto', marginRight: 'auto', marginTop: '25px'}}>
                <Card.Content style={{height: '52px'}}>
                <div className="mx-auto flex align-middle bg-white ">
                    <div className="flex-shrink-0">
                        <img className="h-12 w-12 align-top rounded-full -mt-2"  src="https://scontent-lhr8-1.cdninstagram.com/v/t51.2885-19/s150x150/117952060_3209347775779042_7793420114388656274_n.jpg?_nc_ht=scontent-lhr8-1.cdninstagram.com&_nc_ohc=ijAQP43kWP8AX-T8e-R&oh=9e6c00580ff0520ea6de617e1d61779d&oe=5F83F736" alt="Store Logo"/>
                    </div>
                    <div className="ml-4 pt-1">
                        <h4 className="md:text-left text-base text-gray-900 leading-tight">Pawsitivity Dog Bandanas</h4>
                    </div>
                    <div className="absolute pr-4 pt-1 align-middle right-0" onClick={() => {(window.location = "https://www.etsy.com/shop/PawsitivityDesigns")}}> 
                        <Icon link name='shop' />
                    </div>
                </div>
                </Card.Content>
                    <ImagesWithLoading images={images1} />          
                <Card.Content>
                    <div className="relative">
                        <CopyToClipboard text="DEXTERSFRIEND" onCopy={(text) => {deployCopyToast(`Copied: ${"DEXTERSFRIEND"}`)}}> 
                            <Label as='a' color='blue' >
                                Save 15% with DEXTERSFRIEND
                            </Label>
                        </CopyToClipboard>  

                        <div className="absolute bottom-0 right-0 ">
                            <Icon link name='instagram' size='large' onClick={() => {(window.location = "https://www.instagram.com/pawsitivity.designs/?hl=en")}} />
                        </div>   
                    </div> 
                    <div className="mt-2">
                        <p>⚡𝕊𝕙𝕠𝕨 𝕠𝕗𝕗 𝕪𝕠𝕦𝕣 𝕡𝕖𝕥𝕤 𝕡𝕖𝕣𝕤𝕠𝕟𝕒𝕝𝕚𝕥𝕪 ⚡
                             Custom made to order bandanas 
                        </p>
                    </div>              
                </Card.Content>
                <CopyToClipboard text="DEXTERSFRIEND" onCopy={() => {(window.location = "https://www.etsy.com/shop/PawsitivityDesigns")}}>
                    <Button  attached='bottom' content='Copy Code & Visit Shop' icon='arrow right' labelPosition='right' />
                </CopyToClipboard>     
            </Card>












            <Card style={{marginLeft: 'auto', marginRight: 'auto', marginTop: '25px'}}>
                <Card.Content style={{height: '52px'}}>
                <div className="mx-auto flex align-middle bg-white ">
                    <div className="flex-shrink-0">
                        <img className="h-12 w-12 align-top rounded-full -mt-2"  src="https://scontent-lhr8-1.cdninstagram.com/v/t51.2885-19/s150x150/98109205_253022782606618_5545719977991995392_n.jpg?_nc_ht=scontent-lhr8-1.cdninstagram.com&_nc_ohc=NakS_JJBn20AX8x-bTD&oh=87eaa315f3ea5fd1fced515c412f19d0&oe=5F8214D8" alt="Store Logo"/>
                    </div>
                    <div className="ml-4 pt-1">
                        <h4 className="md:text-left text-base text-gray-900 leading-tight">{"Martini's Digz Shop"}</h4>
                    </div>
                    <div className="absolute pr-4 pt-1 align-middle right-0" onClick={() => {(window.location = "https://www.martinisdigz.com/")}}> 
                        <Icon link name='shop' />
                    </div>
                </div>
                </Card.Content>
                    <ImagesWithLoading images={images2} />          
                <Card.Content>
                    <div className="relative">
                        <CopyToClipboard text={"DEXTER10"} onCopy={(text) => {deployCopyToast(`Copied: ${"DEXTER10"}`)}}> 
                            <Label as='a' color='violet' >
                                Save 10% with DEXTER10
                            </Label>
                        </CopyToClipboard>  

                        <div className="absolute bottom-0 right-0 ">
                            <Icon link name='instagram' size='large' onClick={() => {(window.location = "https://www.instagram.com/martinisdigzshop/?hl=en")}} />
                        </div>   
                    </div> 
                    <div className="mt-2">
                        <p>{"Specializing in trendy, unique dog toys, apparel & accesories🐾"}</p>
                    </div>              
                </Card.Content>
                <CopyToClipboard text={modelCode} onCopy={() => {(window.location = "https://www.martinisdigz.com/")}}>
                    <Button  attached='bottom' content='Copy Code & Visit Shop' icon='arrow right' labelPosition='right' />
                </CopyToClipboard>     
            </Card>
    </Card.Group>
    {/* </Segment> */}
        
      </>
    )
  }
}
