import React from 'react';
import 'semantic-ui-css/semantic.min.css'
import './App.css';
import PlaceholderExampleCard from './placeCard.js';
import SignUpFooter from './signUpFooter.js';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'

toast.configure()
function App() {
  return (
    <div className="App" class="min-h-screen bg-gradient-to-b from-teal-400 via-blue-500 to-purple-400">
      <PlaceholderExampleCard></PlaceholderExampleCard>
      <SignUpFooter></SignUpFooter>
    </div>

    
  );
}

export default App;
