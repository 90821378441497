import React, { Component } from 'react'
//import { Segment, Container, Grid, Header, List, Image, Divider } from 'semantic-ui-react'
import { Container, List, Divider } from 'semantic-ui-react'


export default class SignUpFooter extends Component{

    render() {
        return (
           <> 
            {/* <Segment inverted vertical style={{ margin: '0em 0em 0em', padding: '0em 0em' }}> */}
            <Container textAlign='center' style={{ margin: '0em 0em 0em', padding: '0em 0em' }}>
              {/* <Grid divided inverted stackable>
                <Grid.Column width={3}>
                  <Header inverted as='h4' content='Group 1' />
                  <List link inverted>
                    <List.Item as='a'>Link One</List.Item>
                    <List.Item as='a'>Link Two</List.Item>
                    <List.Item as='a'>Link Three</List.Item>
                    <List.Item as='a'>Link Four</List.Item>
                  </List>
                </Grid.Column>
                <Grid.Column width={3}>
                  <Header inverted as='h4' content='Group 2' />
                  <List link inverted>
                    <List.Item as='a'>Link One</List.Item>
                    <List.Item as='a'>Link Two</List.Item>
                    <List.Item as='a'>Link Three</List.Item>
                    <List.Item as='a'>Link Four</List.Item>
                  </List>
                </Grid.Column>
                <Grid.Column width={3}>
                  <Header inverted as='h4' content='Group 3' />
                  <List link inverted>
                    <List.Item as='a'>Link One</List.Item>
                    <List.Item as='a'>Link Two</List.Item>
                    <List.Item as='a'>Link Three</List.Item>
                    <List.Item as='a'>Link Four</List.Item>
                  </List>
                </Grid.Column>
                <Grid.Column width={7}>
                  <Header inverted as='h4' content='Footer Header' />
                  <p>
                    Extra space for a call to action inside the footer that could help re-engage users.
                  </p>
                </Grid.Column>
              </Grid> */}
        
              <Divider inverted section />
              <List horizontal inverted divided link size='small' style={{ margin: '0em 0em 2em', padding: '0em 0em' }}>
                <List.Item as='a' href='#'>
                  copyright model.dog
                </List.Item>
                {/* <List.Item as='a' href='#'>
                  Contact Us
                </List.Item>
                <List.Item as='a' href='#'>
                  Terms and Conditions
                </List.Item>
                <List.Item as='a' href='#'>
                  Privacy Policy
                </List.Item> */}
              </List>
            </Container>
          {/* </Segment> */}
          </>
        )
    }
}